var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import React, { useEffect, useState } from "react";
import { BaseHeader } from "../base-header/BaseHeader";
import { mapMenuTypeFromBackend } from "mapper/menuMapper";
import { getCurrentUrl } from "../../../utils/urlTools";
var testLogo = {
    id: 1,
    Name: "Test Logo",
    AlternativeText: "Logo",
    Caption: "Test Logo Caption",
    Width: 80,
    Height: 80,
    Ext: ".jpg",
    Mime: "image/jpeg",
    Size: 15,
    //@ts-ignore
    Url: window.logoUrl,
};
//@ts-ignore
var txchatwidgetData = window.txchatwidgetData;
//@ts-ignore
var hiddenMenuUrl = window.hiddenMenuUrl;
var headerProps = {
    logo: testLogo,
    chatAppName: (_a = txchatwidgetData === null || txchatwidgetData === void 0 ? void 0 : txchatwidgetData.appName) !== null && _a !== void 0 ? _a : "",
    chatAppEnv: (_b = txchatwidgetData === null || txchatwidgetData === void 0 ? void 0 : txchatwidgetData.appEnv) !== null && _b !== void 0 ? _b : "",
    publicApiUrl: "https://www.finam.ru/",
    //@ts-ignore
    isAuthenticated: window.isAuth,
};
var getCorrectGlobalMenu = function (menuItems) {
    var currentUrl = getCurrentUrl();
    var element = menuItems.find(function (item) {
        var _a, _b;
        var href = (_a = item.url) !== null && _a !== void 0 ? _a : (_b = item.page) === null || _b === void 0 ? void 0 : _b.Url;
        if ((href !== '' && currentUrl.startsWith(href)) || item.title === 'Обучение') {
            return true;
        }
    });
    return element !== null && element !== void 0 ? element : menuItems[0];
};
var getProductMenu = function (menuItem) {
    if (!(menuItem === null || menuItem === void 0 ? void 0 : menuItem.children)) {
        return [];
    }
    return menuItem.children
        .filter(function (item) { return !hiddenMenuUrl || !hiddenMenuUrl.includes(item.url); })
        .map(function (item) { return item; });
};
export var Header = function () {
    var _a = useState({
        id: 0,
        title: "",
        slug: "",
        items: []
    }), globalMenu = _a[0], setGlobalMenu = _a[1];
    var _b = useState([]), productMenu = _b[0], setProductMenu = _b[1];
    var _c = useState({
        id: 0,
        title: "",
        slug: "",
        items: []
    }), serviceMenu = _c[0], setServiceMenu = _c[1];
    useEffect(function () {
        var fetchMenus = function () { return __awaiter(void 0, void 0, void 0, function () {
            var responseGlobalMenu, responseServicesMenu, dataServicesMenu, newParsedServicesMenu, dataGlobalMenu, newParsedGlobalMenu, correctGlobalMenu, newProductMenu, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, fetch(window.routeGlobalMenu)];
                    case 1:
                        responseGlobalMenu = _a.sent();
                        return [4 /*yield*/, fetch(window.routeServiceMenu)];
                    case 2:
                        responseServicesMenu = _a.sent();
                        return [4 /*yield*/, responseServicesMenu.json()];
                    case 3:
                        dataServicesMenu = _a.sent();
                        newParsedServicesMenu = mapMenuTypeFromBackend(dataServicesMenu.data);
                        return [4 /*yield*/, responseGlobalMenu.json()];
                    case 4:
                        dataGlobalMenu = _a.sent();
                        newParsedGlobalMenu = mapMenuTypeFromBackend(dataGlobalMenu.data);
                        correctGlobalMenu = getCorrectGlobalMenu(newParsedGlobalMenu.items);
                        newProductMenu = getProductMenu(correctGlobalMenu);
                        correctGlobalMenu.children = newProductMenu;
                        setGlobalMenu(newParsedGlobalMenu);
                        setProductMenu(newProductMenu);
                        setServiceMenu(newParsedServicesMenu);
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.error('Ошибка при получении данных меню:', error_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        fetchMenus();
    }, []);
    var updatedHeaderProps = __assign(__assign({}, headerProps), { globalMenu: globalMenu, productMenu: productMenu, servicesMenu: serviceMenu });
    return (<>
        <BaseHeader {...updatedHeaderProps}/>
    </>);
};
